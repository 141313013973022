import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import colors from '../../../common/colors';

interface BadgeProps extends BadgeBoxStylesProps{
  text: string;
  outOfStock?: boolean;
  className?: string;
  dataTestId?: string
  ariaHidden?: boolean;
}

interface BadgeBoxStylesProps {
  alert?: boolean;
  styleOverrides?: {
    width?: number | string;
    height?: number | string;
    padding?: number | string;
  }
}

const useStyles = makeStyles(() => ({
  badgeBox: ({ alert, styleOverrides }: BadgeBoxStylesProps) => ({
    width: 'fit-content',
    height: '18px',
    borderRadius: '3px',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: (alert ? colors.red : colors.gray400),
    padding: '1px 3px',
    color: (alert ? colors.red : colors.gray900),
    ...styleOverrides
  })
}));

const Badge = ({ariaHidden=false, ...props}: BadgeProps) => {
  const {
    className, dataTestId, text, alert, styleOverrides
  } = props;
  const classes = useStyles({ alert, styleOverrides });
  return (
    <Box aria-hidden={ariaHidden} data-testid={dataTestId} className={`${classes.badgeBox} ${className} badgeText`}>
      {text}
    </Box>
  );
};

export default Badge;
