import { useSelector } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { RootState } from '@/rootStateTypes';

const useSodiumAlertForStore = () => {
  const localizationInfo = useSelector(localizationSelectors.storeDetails);
  const [{ enabled: isShowSodiumWarningEnabled }] = useDecision('fr-web-3855-yum-sodium-warning-ui');
  const isYumEcomm: boolean = useSelector(
    (state: RootState) => state.coreConfig.isYumEcomm
  );
  const isShowSodiumAlertForStore = isShowSodiumWarningEnabled && isYumEcomm && localizationInfo?.showSodiumAlerts;

  return {
    isShowSodiumAlertForStore
  };
};

export default useSodiumAlertForStore;
